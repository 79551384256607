// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thanks-js": () => import("../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-work-mail-js": () => import("../src/pages/work/mail.js" /* webpackChunkName: "component---src-pages-work-mail-js" */),
  "component---src-pages-work-office-js": () => import("../src/pages/work/office.js" /* webpackChunkName: "component---src-pages-work-office-js" */),
  "component---src-pages-work-portfolio-js": () => import("../src/pages/work/portfolio.js" /* webpackChunkName: "component---src-pages-work-portfolio-js" */),
  "component---src-pages-work-report-js": () => import("../src/pages/work/report.js" /* webpackChunkName: "component---src-pages-work-report-js" */)
}

